/* General styles */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.buttonConnect,
.buttonC {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.buttonDisconnect {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.buttonConnect:hover,
.buttonC:hover,
.buttonDisconnect:hover {
  background-color: #0056b3;
}

.buttonDisconnect:hover {
  background-color: #c82333;
}

/* Wallet info */
.walletInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.connectedAccount {
  margin: 0;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #28a745; 
  font-weight: bold;
}

.walletAddress {
  color: #000000; 
}

/* Admin header */
.adminHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.coverPhotoContainer {
  position: relative;
  width: 100%;
  max-width: 1800px;
  height: 200px;
  overflow: hidden;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
}

.coverPhotoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.coverPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Photo grid */
.photoGrid {
  position: absolute;
  top: 10px;
  left: 10px;
  display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-gap: 10px;
}

.photoGridItem {
  position: relative;
  width: 50px;
  height: 50px; 
  border: 0px solid #000000; 
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoGridItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photoGridItem a {
  display: block;
  width: 100%;
  height: 100%;
}

.photoGridItem a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Grid Photo */
.gridPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Profile photo */
.profilePhotoContainer {
  position: relative;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #f5f5f5; 
  background-color: white;
  margin-left: 10px;
}

.profilePhotoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.profilePhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Kanal name */
.channelNameContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  margin-left: 10px;
}

.channelName {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
  cursor: pointer;
}

.channelNameInput {
  padding: 10px;
  border: 2px solid #4caf50;
  border-radius: 5px;
  background-color: white;
  color: black;
}

/* Content layout */
.Icerik {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.KolonSOL {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.KolonSAG {
  width: 25%;
  text-align: left;
}

/* Table styles */
.tablex {
  margin-bottom: 20px;
  overflow-x: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #808080; 
  max-height: 450px; 
  overflow-y: auto;
}

.small {
  font-size: 0.9em;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.trx {
  background-color: #f2f2f2;
  padding: 10px;
}

.th {
  border: 1px solid #808080; 
  text-align: left;
}

.trx:hover {
  background-color: #f1f1f1;
}

.ortaIcerik {
  padding: 10px;
  border: 1px solid #808080; 
  text-align: left;
}

.cikti {
  border: 1px solid #808080; 
  padding: 10px;
}

.ortaIcerik,
.vauleTablo {
  text-align: center;
}

.progress-bar-container {
  background-color: #f3f3f3;
  border: 2px solid #4caf50; 
  border-radius: 5px;
  overflow: hidden;
  width: 90%;
  height: 20px;
}

.progress-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
}

.inputVote {
  width: 120px;
  padding: 5px;
  border: 2px solid #4caf50; 
  border-radius: 5px;
}

/* Comment section */
.commentSectionContainer {
  margin-top: 0px;
  text-align: left;
  max-height: 400px;
  overflow-y: scroll;
}

.commentBox {
  width: 95%;
  height: 100px;
  background-color: #ececec;
  padding: 10px;
  border: 1px solid #808080; 
  border-radius: 5px;
}

.commentsList {
  margin-top: 20px;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #f7f7f7;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 95%;
  overflow-wrap: break-word; 
}

.comment p {
  margin: 5px 0;
}

.comment-author {
  font-size: 0.8em;
  color: #555;
}

.comment-date {
  font-size: 0.8em;
  color: #aaa;
}

.comment strong {
  color: #007bff;
}

/* Completed projects */
.completedProjectsContainer {
  border: 1px solid #808080; 
  padding: 20px;
  border-radius: 5px;
  max-height: 450px; 
  overflow-y: auto;
  width: calc(100% - 40px); 
}

.completedProjectsTitle {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.completedProjectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 2px solid #4caf50; 
  border-radius: 10px;
  margin-bottom: 10px;
  background: linear-gradient(to right, #95c906, #4caf50); 
}

.completedProjectPhotos {
  display: flex;
  flex-direction: row;
}

.completedProjectPhotos .photoGridItem {
  margin-right: 10px; 
  position: relative;
  width: 50px;
  height: 50px; 
  border: 0px solid #000000; 
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completedProjectPhotos .photoGridItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Link edit overlay for completed projects */
.linkEditOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Admin panel */
.adminSection {
  border: 2px solid #4caf50; 
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 20px;
}

.adminTitle {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.publishProject,
.addProject {
  margin-top: 20px;
}

.inputID,
.inputName {
  width: calc(100% - 22px);
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #4caf50; 
  border-radius: 5px;
  margin-top: 12px;
}

.linkText {
  color: #007bff;
  text-decoration: none;
}

.linkText:hover {
  text-decoration: underline;
}

/* Loading spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #007bff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* Search box */
.searchBox {
  margin-bottom: 10px;
}

.searchInput {
  width: calc(100% - 22px);
  padding: 10px;
  border: 0px solid #808080;
  background-color: #eeeeee;
  border-radius: 5px;
}

/* Footer */
.footer {
  background-color: transparent;
  padding: 10px;
  text-align: center;
}

.footerLinksContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.greenBox {
  background-color: #1ea9bb;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  border: none;
}

.greenBox:hover {
  background-color: #218838;
}

/* Yorum Havuzu ve Yakılan Toplam */
.yorumhavuzuInfo {
  margin-top: 10px;
  margin-bottom: 20px;
}

.yorumhavuzuInfo p {
  margin: 0;
}

.yorumHavuzu {
  border: 1px solid #155724; 
  background-color: #d4edda; 
  color: #155724; 
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

.yakilanToplam {
  border: 1px solid #721c24; 
  background-color: #f8d7da; 
  color: #721c24; 
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}
